module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToStylesProvider":"src/config/muiStylesProviderProps"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":["title"],"markdownCaptions":false,"backgroundColor":"none","quality":90,"withWebp":true,"disableBgImageOnAlpha":true,"loading":"lazy"},
    },{
      plugin: require('../plugins/gatsby-remark-rewrap-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#F0F4F8","cache_busting_mode":"query","crossOrigin":"anonymous","display":"standalone","icon_options":{},"icon":"./static/images/appLogo.png","theme_color_in_head":true,"theme_color":"#102A43","include_favicon":true,"legacy":true,"cacheDigest":"c94ba898093aa325872901de137b7afe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WC83ZXN","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
