const { IMAGE_CLASS, IMAGE_BACKGROUND_CLASS, IMAGE_CONTAINER_CLASS, } = require(`./constants`);

module.exports = {
    onRouteUpdate(apiCallbackContext, pluginOptions) {
        // This code came from gatsby-remark-images. Meant to keep image loading consistent across my plugin and theirs
        const imageWrappers = document.querySelectorAll(`.${IMAGE_CONTAINER_CLASS}`);

        // https://css-tricks.com/snippets/javascript/loop-queryselectorall-matches/
        // for cross-browser looping through NodeList without polyfills
        for (let i = 0; i < imageWrappers.length; i++) {
            const imageWrapper = imageWrappers[i];
            const backgroundElement = imageWrapper.querySelector(`.${IMAGE_BACKGROUND_CLASS}`);
            const imageElement = imageWrapper.querySelector(`.${IMAGE_CLASS}`);

            const onImageLoad = () => {
                backgroundElement.style.transition = `opacity 0.5s 0.5s`;
                imageElement.style.transition = `opacity 0.5s`;
                onImageComplete();
            };
            const onImageComplete = () => {
                backgroundElement.style.opacity = 0;
                imageElement.style.opacity = 1;
                imageElement.style.color = `inherit`;
                // TODO: Figure out how to set this with the theme?
                imageElement.style.boxShadow = `
                    0px 3px 5px -1px rgba(0,0,0,0.2),
                    0px 6px 10px 0px rgba(0,0,0,0.14),
                    0px 1px 18px 0px rgba(0,0,0,0.12)
                `;
                imageElement.removeEventListener(`load`, onImageLoad);
                imageElement.removeEventListener(`error`, onImageComplete);
            };
            imageElement.style.opacity = 0;
            imageElement.addEventListener(`load`, onImageLoad);
            imageElement.addEventListener(`error`, onImageComplete);

            if (imageElement.complete) onImageComplete();
        }
    }
};