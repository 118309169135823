
module.exports = {
    MDAST_IMAGE_TYPES: ['image', 'imageReference'],
    IMAGES_CONTAINER_CLASS: 'gatsby-remark-rewrap-images-container',
    IMAGE_ITEM_CLASS: 'gatsby-remark-rewrap-images-image-item',
    IMAGE_CONTAINER_CLASS: 'gatsby-remark-rewrap-images-image-container',
    IMAGE_LINK_CLASS: 'gatsby-remark-rewrap-images-image-link',
    IMAGE_BACKGROUND_CLASS: 'gatsby-remark-rewrap-images-image-background',
    IMAGE_CLASS: 'gatsby-remark-rewrap-images-image',
};
