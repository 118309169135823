
import * as shortid from 'shortid';
import { jssPreset, createGenerateClassName } from '@material-ui/styles';
import { create } from 'jss';

// Styles in production build mode is pretty fucked up with MUI at the moment. Lots to read and implement when it comes
// time to make this public.
// https://github.com/gatsbyjs/gatsby/issues/8237  potential solutions in here
// https://github.com/gatsbyjs/gatsby/issues/17676 potential solutions in here
// https://github.com/gatsbyjs/gatsby/issues/8560  potential solutions in here
// https://github.com/gatsbyjs/gatsby/issues/15954

export const JSS_SEED = process.env.NODE_ENV !== 'production' ? '' : shortid.generate();
// The only reason that the styles work between SSR and the Browser is because there are no config options....
// I've tried every permutation of shit to make things work. I have no idea what's going on.
export const JSS_CLASS_NAME_GENERATOR = createGenerateClassName(); // { productionPrefix: , seed: JSS_SEED }

export const JSS_INSTANCE = create({
    ...jssPreset(),
    createGenerateId: () => JSS_CLASS_NAME_GENERATOR,
    // insertionPoint: 'jss-insertion-point'
});

export const STYLES_PROVIDER_PROPS = {
    injectFirst: false, // Can't use this and a custom jss instance at the same time
    jss: JSS_INSTANCE,
    generateClassName: JSS_CLASS_NAME_GENERATOR
};

export default STYLES_PROVIDER_PROPS;
